.contentHeader {
  position: fixed;
  display: flex;
  width: ~"calc(100% - 245px)";
  top: 64px;
  margin-left: 245px;
  padding: 15px 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  z-index: 9;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 4px;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 0.5;
}

.rightArea {
  display: flex;
  align-items: center;
  margin-left: auto;
}
