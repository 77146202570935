.tableHeader {
  display: flex;
  flex-direction: row;
  margin-left: 279px;
  text-transform: uppercase;
  background: #ffffff;
  border: solid 1px #e6e6e6;
}

.activityHeading {
  text-transform: uppercase;
  padding: 14px 30px;
  min-width: 170px;
  border-right: solid 1px #e6e6e6;
}

.monthHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  text-transform: uppercase;
  border-right: solid 1px #e6e6e6;
}

.objectiveCollapse {
  margin: 14px 30px;
  margin-top: 4px;
  background: none;
  border: none;
}

.objectiveCollapse :global(.ant-collapse-item) {
  border-bottom: none !important;
}

.objectiveCollapse :global(.ant-collapse-content) {
  background: transparent !important;
  border-top: none !important;
}

.objectiveCollapse :global(.ant-collapse-content-box) {
  padding: 0 !important;
}

.objectiveCollapse :global(.ant-collapse-header) {
  padding: 0 !important;
}

.objectiveHeader {
  padding: 14px 30px;
  width: 100%;
  text-transform: uppercase;
  background: #d9d9d9;
}

.goalContainer {
  display: flex;
  background: #ffffff;
  border-bottom: solid 1px #e6e6e6;
}

.goalCell {
  width: 250px;
  border-right: solid 1px #e6e6e6;
}

.goalTitleContainer {
  margin-top: 15px;;
  padding: 0px 30px;
}

.goalSummaryContainer {
  text-align: center;
  padding: 30px;
}

.activityTable {
  background-color: #ffffff;
}

.activityRow {
  position: relative;
  display: flex;
  border-bottom: solid 1px #e6e6e6;
}

.activityNameCell {
  padding: 15px 20px;
  max-width: 170px;
  min-width: 170px;
  border-right: solid 1px #e6e6e6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.activityRange {
  position: absolute;
  height: 52px;
  margin-left: 139px;
  background-color: rgba(142, 29, 65, 0.5);
  z-index: 10;
}

.activityCell {
  position: relative;
  padding: 25.5px 0px;
  max-width: 80px;
  min-width: 80px;
  border-right: solid 1px #e6e6e6;
}

.activityCell:hover {
  box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.activityCell:active {
  box-shadow: inset 0px 0px 0px 4px rgba(142, 29, 65, 0.5);
}

.activityCell[data-active="true"] {
  position: relative;
  padding: 25.5px 0px;
  max-width: 80px;
  min-width: 80px;
  background-color: rgba(142, 29, 65, 0.5);
  border-right: solid 1px rgba(142, 29, 65, 0.25);
}

.cellTrigger {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
