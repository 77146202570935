:global(.ant-collapse-header) {
  display: flex;
  align-items: center;
}

:global(.ant-collapse-header) :global(.ant-collapse-extra) {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.panel {
  display: flex;
  flex: 1;
  padding: 12px 16px;
  border-left:  solid 3px;
  align-items: center;
}

.panel:hover {
  box-shadow: inset 0px 0px 0px 100px rgba(0, 0, 0, 0.05);
}

.panel span {
  flex: 1;
}

.panel span :global(.ant-typography) {
  margin-bottom: 0px;
}

.panel span :global(.ant-typography.ant-typography-edit-content) {
  margin-bottom: 0px;
  height: 0px;
}

.panel span :global(.ant-typography.ant-typography-edit-content) :global(.anticon) {
  position: absolute;
  top: 8px;
}

:global(.ant-collapse-item) {
  position: relative;
}

:global(.ant-collapse-item)::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: -1px;
  left: 0;
  margin-left: -19px;
  width: 100%;
  height: 100%;
  border-left: solid 1px #d9d9d9;
}

:global(.ant-collapse-item):last-child::after, :global(.ant-collapse-item):global(.root)::after {
  content: none;
}

.panel::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: calc(-50% + 2px);
  left: 0;
  margin-left: -20px;
  width: 100%;
  height: calc(100% + 2px);
  border-left: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
}

.panel.button {
  padding: 8px 0;
  border-left: none;
}

.panel.generalObjective::before,
.panel.impactIndicator::before {
  content: none;
}

.panel.specificObjective::after {
  top: -27px;
  height: 50px;
}

.generalObjective, .impactIndicator {
  border-left-color: #fa541c;
}

.specificObjective {
  border-left-color: #df9400;
}

.goal {
  border-left-color: #e2346b;
}

.activity {
  border-left-color: #006565;
}

.title {
  display: inline-block;
  margin-right: 20px;
}

.generalObjective .title, .impactIndicator .title {
  color: #fa541c;
}

.specificObjective .title {
  color: #df9400;
}

.goal .title {
  color: #e2346b;
}

.activity .title {
  color: #006565;
}
