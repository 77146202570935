.modal :global(.ant-modal-content) {
  text-align: left;
  border-radius: 0;
}

.modal :global(.ant-modal-body) {
  padding: 30px;
}

.modal :global(.ant-modal-footer) {
  text-align: left;
  padding: 30px;
}

.noPadding :global(.ant-modal-body) {
  padding: 0;
}

.centeredModal :global(.ant-modal-content) {
  text-align: center;
}

.centeredModal :global(.ant-modal-footer) {
  text-align: center;
}

.slimFooter :global(.ant-modal-footer) {
  overflow: hidden;
  padding: 15px 30px;
  text-align: right;
}
