.hoveringBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 24px;
  width: 100%;
  background-color: rgba(142, 29, 65, 0.8);
  z-index: 1;
}

.hoveringBar :global(.ant-btn-link):hover, .hoveringBar :global(.ant-btn-link):focus {
  color: #ffffff;
}

.hoveringBar :global(.ant-btn-round.ant-btn-background-ghost):hover, .hoveringBar :global(.ant-btn-round.ant-btn-background-ghost):focus {
  border-color: #ffffff;
  color: #ffffff;
}
