.simpleInput :global(.ant-input) {
  background-color: #f8f8f8;
  border: none;
  border-radius: 0px;
}

.simpleInput :global(.ant-input):focus {
  box-shadow: none;
}

