.container {
  overflow: hidden;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
}

.monthContainer {
  float: left;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border-right: solid 1px #f3f3f3;
}

.tabContainer {
  float: left;
}

.tabContainer a {
  color: inherit;
}

.tab {
  cursor: pointer;
  display: inline-block;
  margin: 0 15px;
  padding: 23px 0;
}

.tab:first-child {
  margin-left: 30px;
}

.tab:hover, .tab.active {
  color: #8e1d41;
}

.tab:active {
  opacity: 0.5;
}

.tab.active {
  color: #8e1d41;
  box-shadow: inset 0px -2px 0px 0px #8e1d41;
}
