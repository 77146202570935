.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.container.vertical {
flex-direction: column;
}

.label {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: #B3B3B3;
}

.contentContainer {
  vertical-align: top;
  display: inline-block;
}
