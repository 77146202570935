.reviewControlsContainer {
  position: absolute;
  right: -20px;
  transform: translate(100%, -100%);
  white-space: nowrap;
}

.relativeReviewControlsContainer {
  white-space: nowrap;
}

.approved, .approved:hover, .approved:focus {
  color: #ffffff;
  background-color: #43B964;
}

.rejected, .rejected:hover, .rejected:focus {
  color: #ffffff;
  background-color: #B94C65;
}

.commentButtonContainer {
  display: inline-block;
  margin-left: 10px;
}

.withComment, .withComment:hover, .withComment:focus {
  color: #ffffff;
  background-color: #FF9838;
}

:global(.activity-form-item) .relativeReviewControlsContainer {
  margin-top: 4px;
  margin-bottom: 10px;
  float: right;
}
