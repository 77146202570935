.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: solid 1px #e6e6e6;
  padding: 14px;
  margin-top: 16px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
