@import "../../../theme.less";

.card {
  cursor: pointer;
  padding: 28px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
}

.card[data-active="true"] {
  border-color: @primary-color;
  color: @primary-color;
}

.card[data-disabled="true"] {
  border-color: #d9d9d9;
  color: #a7a7a7;
  background-color: #eaeaea;
  cursor: initial;
}

.tooltipContainer {
  position: absolute;
  top: 10px;
  right: 20px;
  color: @primary-color;
}

.title {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 0.5;
}

.description {
  margin-top: 2px;
  font-size: 16px;
  line-height: 20px;
}

:global(.ant-popover-inner-content) {
  width: 400px;
}
