.header {
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  z-index: 10;
}

.logoContainer, .navContainer, .navItem {
  display: inline-block;
  height: 100%;
}

.logoContainer {
  padding: 24px;
}

.navContainer {
}

.navItem {
  cursor: pointer;
  font-size: 16px;
  padding: 24px;
}
