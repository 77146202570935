.formSection {
  margin-bottom: 24px;
}

.formSection :global(.ant-form-item) {
  margin-bottom: 8px;
}

.formSection:last-child {
  margin-bottom: 0px;
}
