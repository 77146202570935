.table {
  margin-bottom: 20px;
}

.table :global(.ant-table-thead) > tr:last-child > th {
  background: #ffffff;
  color: rgba(0,0,0,0.5);
}

.table :global(.ant-table-tbody) > tr > td {
  background: #ffffff;
}
