.controlButton {
  margin: 0 5px;
}

.controlButton:first-child {
  margin-left: 0;
}

.controlButton:last-child {
  margin-right: 0;
}
