.itemLabel {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 168px;
}

.menu:global(.ant-menu-vertical) {
  border-right: none !important;
}
