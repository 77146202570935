@import "../../../theme.less";

h5 {
    color: #8e1d41;
}

p {
    font-size: 13px;
}

.addStyle {
    background-color: #fff;
}
