.projectFileTree:global(.ant-collapse-borderless) {
  background-color: transparent;
}

.projectFileTree :global(.ant-collapse-item) {
  border-bottom: none !important;
}

.projectFileTree :global(.ant-collapse-header) {
  padding: 0 !important;
  background-color: #ffffff;
  border: solid 1px #f5f5f5;
  border-radius: 2px;;
}

.projectFileTree :global(.ant-collapse-content-box) {
  padding: 0 !important;
  padding-left: 40px !important;
}

.box {
  padding: 10px;
  margin-bottom: 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
}

.box:last-child {
  margin-bottom: 0px;
}

.boxWrapper {
  display: flex;
  align-items: center;
}

.boxWrapper :global(.ant-row.ant-form-item) {
  flex: 1;
  margin-bottom: 0px;
}

.boxWrapper :global(.ant-row.ant-form-item.ant-form-item-with-help) {
  margin-bottom: 5px;
}

.reviewControlsWrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
