.table {
  margin-bottom: 20px;
}

.table :global(.ant-table-column-title) {
  white-space: nowrap;
}

.table :global(.ant-table-thead) {
  /* text-transform: uppercase; */
}

.table :global(.ant-table-thead) > tr > th {
  background: #ffffff;
  color: rgba(0,0,0,0.5);
}

.table :global(.ant-table-tbody) > tr > td {
  background: #ffffff;
}

// BUDGET PER TYPE TABLE
.BudgetPerType :global(.ant-table-thead) > tr > th {
  text-align: right;
}

.BudgetPerType :global(.ant-table-thead) > tr > th:nth-child(1) {
  text-align: left;
}

// ALL SUPPLIES TABLE 
.AllSuppliesTable :global(.ant-table-thead) > tr > th {
  text-align: right;
}

// exclude the first 3 elements
.AllSuppliesTable :global(.ant-table-thead) > tr > th:nth-child(-n+3) {
  text-align: left;
}

/* BUDGET PER YEAR CUSTOM TABLE */
.Table__budgetPerYear {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.TableHead__budgetPerYear {
  text-align: right;
}

.TableRow_budgetPerYear {
  margin-bottom: 120px !important;
  padding: 0px !important;
}

th.align-right,
td.align-right {
  text-align: right !important;
}

.Cell {
  background-color: #8ff !important;
}

.Cell__alignLeft {
  text-align: left;
}

.Cell__alignRight {
  text-align: right;
}
