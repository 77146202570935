.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: 65px;
  width: 245px;
  background-color: #ffffff;
  border-right: solid 1px #e6e6e6;
  z-index: 11;
}

.sidebarHeading {
  padding: 12px 16px;
}

.sidebarHeading > h2 {
  margin: 0;
}
