@import "../../../theme.less";

.indicatorContainer {
  position: relative;
  display: inline-block;

  font-size: 13.5px;

  margin: 16px 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: #808080;
  background-color: #f5f5f5;
}

.indicatorContainer:first-child {
  float: left;
  margin-right: 16px;
}

.indicatorContainer:last-child {
  float: right;
  margin-left: 16px;
}

:global(.ant-menu-item-selected) > .indicatorContainer, .activeIndicator {
  color: #ffffff;
  background-color: @primary-color;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
}

.check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-8px, -50%);
}
