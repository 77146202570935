.panel {
  background: none;
  border-bottom: none !important;
  margin-bottom: 20px;
}

.panel :global(.ant-collapse-content) {
  overflow: visible !important;
}

.panel :global(.ant-collapse-header) {
  padding: 0 !important;
}

.panel :global(.ant-collapse-extra) {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 20px;
}

.panelHeader {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  background-color: #f5f5f5;
  border-bottom: solid 1px #e6e6e6;
}

.collapse {
  background: none;
}

.collapse :global(.ant-collapse-content-box) {
  padding: 0;
  padding-top: 20px !important;
}
